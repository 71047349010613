
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import { AppliedFilters } from "@/interfaces/misc";
import { Therapist } from "@/interfaces/therapist";
import Filters from "@/components/Filters.vue";
import TheTherapist from "@/components/TheTherapist.vue";
import { DataDownloader, get } from "@/api";
import i18n from "@/i18n";
const pageKey = "therapists";

@Component({
  components: {
    Filters,
    TheTherapist,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class TherapistList extends Vue {
  therapists = new DataDownloader<Therapist[]>(get.therapists);

  appliedFilters: AppliedFilters = {
    meetingType: "first-visit",
    visitType: "has_online_dates",
    specializations: [],
  };

  get sortedTherapists() {
    if (this.therapists.data)
      return [...this.therapists.data].sort((a: Therapist, b: Therapist) => {
        const aHasAnySpec = a.specializations.some((spec) =>
            this.appliedFilters.specializations.includes(spec)
          ),
          bHasAnySpec = b.specializations.some((spec) =>
            this.appliedFilters.specializations.includes(spec)
          );
        if (aHasAnySpec && !bHasAnySpec) return -1;
        else if (!aHasAnySpec && bHasAnySpec) return 1;
        const aHasVisits = a[this.appliedFilters.visitType],
          bHasVisits = b[this.appliedFilters.visitType];
        if (aHasVisits && !bHasVisits) return -1;
        else if (!aHasVisits && bHasVisits) return 1;

        return 0;
      });
    else return null;
  }

  filtersModal() {
    this.$openModal({
      component: "Filters",
      props: this.appliedFilters,
    });
  }
}
