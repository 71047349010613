
import { Therapist } from "@/interfaces/therapist";
import { TherapistDates, VisitDict, visitName } from "@/interfaces/visit";
import Vue from "@/interfaces/vue";
import { Component, Prop } from "vue-property-decorator";
import Calendar from "@/components/Calendar.vue";
import { DataDownloader, get } from "@/api";
import { Fragment } from "vue-fragment";

@Component({
  components: { Calendar, Fragment },
})
export default class TheTherapist extends Vue {
  @Prop()
  therapist!: Therapist;
  @Prop()
  meetingName!: string;
  @Prop()
  visitName!: visitName;

  therapistDates = new DataDownloader<TherapistDates>(() =>
    get.therapistDates(this.therapist.id)
  );
  price: number | null = null;
  currency: string | null = null;
  baseUrl = process.env.VUE_APP_TARGET;

  visitMap: VisitDict = {
    has_online_dates: "available_online",
    has_chat_dates: "available_for_chat",
    has_office_dates: "available_in_office",
  };

  get dates() {
    if (this.therapistDates)
      return this.therapistDates.data?.buyable.filter(
        (date) => date[this.visitMap[this.visitName]]
      );
    else return [];
  }

  get visitTypes() {
    if (this.$store.state.dict) {
      const visitTypes = this.$store.state.dict.visit_types;

      return this.therapistDates.data?.visit_types.map((visit) => ({
        ...visit,
        visit_type: visitTypes.find((type) => type.id === visit.visit_type)
          ?.name,
      }));
    } else return [];
  }

  get visit() {
    if (this.visitTypes?.length) {
      return this.visitTypes.find(
        (type) => type.visit_type === this.meetingName
      );
    } else return { duration: 0 };
  }

  get specializations() {
    return this.therapist.specializations
      .map((spec) => this.$t(`therapist.list.${spec}`))
      .join(", ");
  }

  async getPrice() {
    try {
      const { data } = await get.therapistDates(this.therapist.id);
      data.visit_types.forEach((visit) => {
        if (!this.price || this.price > visit.price)
          this.price = parseInt(visit.price.toString());
      });
      this.currency = data.accepted_currency;
    } catch (err) {
      console.log(err);
    }
  }

  async mounted() {
    this.getPrice();
  }

  openModal(date: Date) {
    this.$openModal({
      component: "Booking",
      props: {
        therapist: this.therapist,
        therapistDates: this.therapistDates.data,
        pickedDate: date,
        visitTypes: this.visitTypes,
        visitName: this.visitName,
        meetingName: this.meetingName,
      },
      closeButton: true,
      title: "therapist.book-visit",
    });
  }
}
