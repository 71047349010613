
import { Vue, Component, Prop } from "vue-property-decorator";
import { Fragment } from "vue-fragment";
import { Dictionaries } from "@/interfaces/dictionary";

@Component({ components: { Fragment } })
export default class Filters extends Vue {
  @Prop() props!: Record<string, any>;
  @Prop({ default: false }) normalView!: boolean;

  get dictionary(): Dictionaries {
    return this.$store.state.dict;
  }
  get specializations() {
    if (this.dictionary && this.dictionary.specialization_ids)
      return this.dictionary.specialization_ids.map(
        (spec) => spec.specialization
      );
    else return null;
  }
}
